import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax

import "mapbox-gl/dist/mapbox-gl.css"
import "./mapbox.css"

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

const Mapbox = ({ longitude, latitude, city, customZoom }) => {
  const mapContainerRef = useRef(null)
  const [geoJson, setGeoJson] = useState(null)
  const mapRef = useRef(null)
  const markersRef = useRef([])

  useEffect(() => {
    // Dynamically import the GeoJSON file based on the city name
    import(`../data/${city}.json`)
      .then((module) => setGeoJson(module.default))
      .catch((error) =>
        console.error(`Failed to load GeoJSON for ${city}:`, error)
      )
  }, [city])

  useEffect(() => {
    if (!geoJson) return

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [longitude, latitude],
      zoom: customZoom,
    })
    mapRef.current = map

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    const markers = []

    // Function to handle "Hébergement" category
    const handleHebergementMarker = (feature) => {
      const { coordinates } = feature.geometry
      const { name, address, website, category, subcategory, review } =
        feature.properties

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
        <h3><a href="${website}" target="_blank" rel="noopener noreferrer">${name}</a></h3>
        <p><strong>${category}</strong> - ${subcategory}</p>
        ${review ? `<p>${review}</p>` : ""}
        <p class="popup-address"><i>${address}</i></p>
      `)

      const marker = new mapboxgl.Marker({ color: "#1a73e8" }) // Distinct color for Hébergement
        .setLngLat(coordinates)
        .setPopup(popup)
        .addTo(map)

      markers.push({ marker, popup })
    }

    const adjustPopupWidth = (popup) => {
      if (window.innerWidth > 768) {
        popup.setMaxWidth("440px")
      } else {
        popup.setMaxWidth("220px")
      }
    }

    // Function to handle "A voir" category
    const handleAvoirMarker = (feature) => {
      const { coordinates } = feature.geometry
      const { name, description, image, link, credit } = feature.properties

      let baseUrl,
        imageWidths,
        imageHTML = ""

      if (image) {
        const { subfolders, fileName } = image
        baseUrl = `https://upload.wikimedia.org/wikipedia/commons/thumb/${subfolders}/${fileName}`
        imageWidths = {
          small: 200, // Size for smaller screens
          small2x: 400, // High-DPI for smaller screens
          medium: 135, // Size for larger screens
          medium2x: 270, // High-DPI for larger screens
        }
        const title = credit ? `${name} - ${credit}` : name
        imageHTML = `
        <div style="height: 120px;display: grid;place-items: center">
          <img 
            srcSet="
            ${baseUrl}/${imageWidths.small}px-${fileName} 200w,
            ${baseUrl}/${imageWidths.small2x}px-${fileName} 400w,
            ${baseUrl}/${imageWidths.medium}px-${fileName} 135w,
            ${baseUrl}/${imageWidths.medium2x}px-${fileName} 270w
            "
            sizes="(max-width: 575px) 200px, (min-width: 576px) 135px"
            src="${baseUrl}/${imageWidths.small}px-${fileName}"
            alt="${title}" 
            title="${title}" 
            loading="lazy" 
            onError="this.style.display='none'" 
          />
        </div>`
      }

      const popup = new mapboxgl.Popup({ offset: 25 })
      adjustPopupWidth(popup)

      // Conditionally add the "must-see-content" class only if there is imageHTML
      popup.setHTML(`
      <div class="${imageHTML ? "must-see-content" : ""}">
        ${imageHTML} <!-- Add the image or placeholder -->
        <div>
          <h3>${
            link
              ? `<a href="${link}" target="_blank" rel="noopener noreferrer">${name}</a>`
              : name
          }</h3>
          ${description ? `<p>${description}</p>` : ""}
        </div>
      </div>
    `)

      const marker = new mapboxgl.Marker({ color: "#b8c6db" }) // Different color for "A voir"
        .setLngLat(coordinates)
        .setPopup(popup)
        .addTo(map)

      markers.push({ marker, popup })
    }

    // Handle window resize and update popup widths
    const handleResize = () => {
      markers.forEach(({ popup }) => adjustPopupWidth(popup))
    }

    // Loop through geoJson features
    geoJson.features.forEach((feature) => {
      const { category } = feature.properties

      if (category === "Hébergement") {
        handleHebergementMarker(feature)
      } else if (category === "A voir") {
        handleAvoirMarker(feature)
      }
    })

    // Store markers in the ref
    markersRef.current = markers

    // Listen for window resize events
    window.addEventListener("resize", handleResize)

    // Clean up on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove()
      }

      markers.forEach(({ marker }) => marker.remove())
      window.removeEventListener("resize", handleResize)
    }
  }, [geoJson, longitude, latitude, customZoom])

  return <div className="map-container" ref={mapContainerRef} />
}

export default Mapbox
